<style lang="scss">
.poster {
  .tabelle-box {
    .head-row {
      [class*='col-'] {
        text-transform: none !important;
      }
    }
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  h3 {
    margin-top: 0;
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      margin: 10px 0;
      background: #ccc;
    }
  }
  .single-row {
    margin-bottom: 20px;

    img {
      max-height: 300px;
      display: block;
      margin: 20px auto;
    }
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
      }
    }
    .bild-box {
      @media (max-width: $width-lg) {
        margin-bottom: 50px;
      }
    }
  }
}
</style>

<template>
  <div class="poster content container">
    <h1>Poster</h1>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/poster.jpg" alt="Poster" class="img-fluid" />
    </div>
    <ul class="content-ul">
      <li><strong>Aussagekräftig und ausdrucksstark. </strong> Nutzen Sie die einzigartige Möglichkeit einer großflächigen Produktpräsentation.</li>
      <li><strong>Ausreichend Platz.</strong> Noch mehr Platz für Ihre Werbebotschaft in drei verschiedenen Größen.</li>
      <li><strong>Für alle,</strong> die groß denken. Weil es manchmal eben doch auf die Größe ankommt.</li>
      <li><strong>Flexibel.</strong> Alle drei Varianten des Posters können als Beilage oder als Mantel um die Kleine Zeitung gebucht werden.</li>
    </ul>
    <div class="whitespace"></div>
    <h2>Ihre Werbemöglichkeiten</h2>
    <TabGroup>
      <Tab tabid="poster-tab1" :title="'Poster'" active>
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/poster.png" alt="Poster" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Poster</h3>
            <div class="row">
              <!--<div class="col-md-12">
                <p><strong>Format</strong><br />600 x 450 mm (575 x 414 mm - 7 mm Abstand zum Bund)</p>
              </div>-->
              <div class="col-md-12">
                <p><strong>Papier</strong><br />Newsprint 55 oder 80 g/m²</p>
              </div>
              <div class="col-md-12">
                <p><strong>Verarbeitung</strong><br />geklebt oder gefalzt auf ein Endformat von 225 x 300 mm</p>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="whitespace"></div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tabelle-box">
              <div class="headline">Druckkosten - Poster<br /></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Stück</div>
                <div class="col-lg-4 text-align-right">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 text-align-right">Papier 80 g / Gewicht 22 g</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">1.447,77</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">1.858,56</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">1.825,33</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.447,57</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.202,89</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.036,57</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.580,46</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.625,58</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.958,02</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">4.214,59</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">4.845,84</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">7.159,62</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">150.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">6.733,66</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">10.104,66</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">8.621,48</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">13.049,69</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">250.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">10.509,30</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">15.994,72</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">12.397,12</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">18.939,76</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">weitere 1.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 15 g</div>
                <div class="col-lg-4 col-6 text-align-right">37,76</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">58,90</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="poster-tab2" :title="'Poster XL'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/poster-xl.png" alt="Poster XL" class="img-fluid" />
              <small class="text-left">Klebung links oder rechts</small>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Poster XL</h3>
            <div class="row">
              <!--<div class="col-md-12">
                <p><strong>Format</strong><br />900 x 450 mm (855 x 414 mm - 7 mm Abstand zum Bund)</p>
              </div>-->
              <div class="col-md-12">
                <p><strong>Papier</strong><br />Newsprint 55 oder 80 g/m²</p>
              </div>
              <div class="col-md-12">
                <p>
                  <strong>Verarbeitung</strong><br />
                  geklebt oder gefalzt auf ein Endformat von 225 x 300 mm
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="whitespace"></div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tabelle-box">
              <div class="headline">Druckkosten - Poster XL<br /></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Stück</div>
                <div class="col-lg-4 text-align-right">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 text-align-right">Papier 80 g / Gewicht 32 g</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">1.547,55</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.047,16</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.007,64</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.804,31</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.467,74</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.561,46</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.927,84</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">4.318,61</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.387,93</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">5.075,76</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">5.688,42</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">8.861,50</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">150.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">7.988,90</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">12.647,24</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">10.289,39</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">16.432,98</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">250.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">12.589,87</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">20.218,72</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">14.890,36</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">24.004,46</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">weitere 1.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 22 g</div>
                <div class="col-lg-4 col-6 text-align-right">46,01</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 32 g</div>
                <div class="col-lg-4 col-6 text-align-right">75,71</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="poster-tab3" :title="'Poster Superpanorama'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/poster-superpanorama.png" alt="Poster Superpanorama" class="img-fluid" />
              <small class="text-left">Klebung links oder rechts</small>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Poster Superpanorama</h3>
            <div class="row">
              <!--<div class="col-md-12">
                <p><strong>Format</strong><br />1200 x 450 mm (1175 x 414 mm - 7 mm Abstand zum Bund)</p>
              </div>
            -->
              <div class="col-md-12">
                <p><strong>Papier</strong><br />Newsprint 55 oder 80 g/m²</p>
              </div>
              <div class="col-md-12">
                <p>
                  <strong>Verarbeitung</strong><br />
                  geklebt oder gefalzt auf ein Endformat von 225 x 300 mm
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="whitespace"></div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tabelle-box">
              <div class="headline">Druckkosten - Poster Superpanorama<br /></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Stück</div>
                <div class="col-lg-4 text-align-right">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 text-align-right">Papier 80 g / Gewicht 43 g</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">1.646,99</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.236,09</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.189,26</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.161,71</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">2.731,54</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">4.087,33</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.273,82</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">5.012,94</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">3.816,10</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">5.938,56</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">6.527,49</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">10.566,65</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">150.000</div>
                <div class="col-6 text-align-left visible-small">Papier 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">9.238,88</div>
                <div class="col-6 text-align-left visible-small">Papier 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">15.194,73</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
                <div class="col-6 text-align-left visible-small">Seiten 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">11.950,27</div>
                <div class="col-6 text-align-left visible-small">Seiten 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">19.822,82</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">250.000</div>
                <div class="col-6 text-align-left visible-small">Seiten 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">14.661,66</div>
                <div class="col-6 text-align-left visible-small">Seiten 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">24.450,91</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
                <div class="col-6 text-align-left visible-small">Seiten 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">17.373,05</div>
                <div class="col-6 text-align-left visible-small">Seiten 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">29.078,99</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">weitere 1.000</div>
                <div class="col-6 text-align-left visible-small">Seiten 55 g / Gewicht 30 g</div>
                <div class="col-lg-4 col-6 text-align-right">54,23</div>
                <div class="col-6 text-align-left visible-small">Seiten 80 g / Gewicht 43 g</div>
                <div class="col-lg-4 col-6 text-align-right">92,56</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </TabGroup>

    <span class="info-txt">Die Kosten für diese Sonderwerbeform setzen sich aus Druck- und Verarbeitungskosten zusammen. </span>

    <!-- -->

    <div class="row">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten als Beilage
            <div class="visible-small transform-none">unter 80.000 Stück</div>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">unter 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">bis 20 g</div>
            <div class="col-lg-4 text-align-right">bis 40 g</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">118,80</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">127,65</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">123,90</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">133,15</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten als Beilage
            <div class="visible-small transform-none">über 80.000 Stück</div>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">über 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">bis 20 g</div>
            <div class="col-lg-4 text-align-right">bis 40 g</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">117,00</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">125,80</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">122,10</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">131,20</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten als Mantel
            <div class="visible-small transform-none">unter 80.000 Stück</div>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">unter 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">bis 20 g</div>
            <div class="col-lg-4 text-align-right">bis 40 g</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">415,80</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">446,78</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">433,65</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">466,03</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten als Mantel
            <div class="visible-small transform-none">über 80.000 Stück</div>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">über 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">bis 20 g</div>
            <div class="col-lg-4 text-align-right">bis 40 g</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">409,50</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">440,30</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
            <div class="col-6 text-align-left visible-small">bis 20 g</div>
            <div class="col-lg-4 col-6 text-align-right">427,35</div>
            <div class="col-6 text-align-left visible-small">bis 40 g</div>
            <div class="col-lg-4 col-6 text-align-right">459,20</div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-box-spezifikationen"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen finden Sie hier.</router-link></div>
    <div class="info-txt-preis">Alle Preise verstehen sich per 1000 Stück in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer.</div>
    <!--<div class="row image-box margin-50-0">
      <div class="col-md-12 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/poster-antenne-innen.png" alt="Antenne Innen" class="img-fluid " />
      </div>
      <div class="col-md-12 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/poster-antenne-aussen.png" alt="Antenne Aussen" class="img-fluid " />
      </div>
    </div>-->

    <!-- -->

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/beilagen/Poster.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarife Poster
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>54 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Poster',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
